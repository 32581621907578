import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { MainSection } from 'src/components/common/mainSection/MainSection'

export const Main = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulHome {
          mainHeader
          mainParagraph
        }
        allContentfulAsset(filter: { title: { in: ["web-home-hero", "mobile-home-hero"] } }) {
          nodes {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { mainHeader, mainParagraph } = data.contentfulHome
      const images = data.allContentfulAsset.nodes

      return <MainSection images={images} heading={mainHeader} paragraph={mainParagraph} page='index' />
    }}
  />
)
