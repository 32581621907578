import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'
import { Heading } from 'src/components/common/heading/Heading'
import { Paragraph } from 'src/components/common/paragraph/Paragraph'
import { Button } from 'src/components/common/button/Button'
import { device } from 'src/themes/breakpoints'

const AboutSectionWrapper = styled.section`
  margin: 4.5rem 0;

  @media ${device.mobileXl} {
    margin-top: 10%;
  }

  @media ${device.tablet} {
    margin-top: 15%;
  }

  @media ${device.laptop} {
    margin-top: 5%;
  }

  @media ${device.laptopL} {
    margin-top: 2rem;
  }

  @media ${device.laptopXl} {
    margin-top: 0;
  }
`

const StyledWrapper = styled(Wrapper)`
  @media ${device.mobileXl} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const StyledImage = styled(Img)`
  display: none;

  @media ${device.mobileXl} {
    display: block;
    flex-basis: 50%;
  }
`

const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${device.mobileXl} {
    padding-left: 2rem;
    flex-basis: 40%;
  }

  @media ${device.tablet} {
    flex-basis: 35%;
  }
`

const StyledHeader = styled(Heading)`
  text-align: right;
  margin-bottom: 1rem;

  @media ${device.laptop} {
    margin-bottom: 1.5rem;
  }
`

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.bgBlue};
`

const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fonts.size.m};
  text-align: right;
  margin-bottom: 3rem;

  @media ${device.laptop} {
    margin-bottom: 4rem;
  }
`

const HomeAboutSection = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulHome {
          aboutHeader
          aboutParagraph
        }
        contentfulAsset(title: { eq: "web-home-about-us" }) {
          fluid(maxWidth: 490) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    `}
    render={data => {
      const { aboutHeader, aboutParagraph } = data.contentfulHome
      const { fluid: aboutImg } = data.contentfulAsset

      return (
        <AboutSectionWrapper>
          <StyledWrapper>
            <StyledImage fluid={aboutImg} />
            <StyledTextWrapper>
              <StyledHeader h2='true' as='h2'>
                {aboutHeader}
              </StyledHeader>
              <StyledParagraph>{aboutParagraph}</StyledParagraph>
              <StyledButton to='/kim-jestesmy'>Więcej &gt;</StyledButton>
            </StyledTextWrapper>
          </StyledWrapper>
        </AboutSectionWrapper>
      )
    }}
  />
)

export default HomeAboutSection
