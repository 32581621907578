import React from 'react'

import { MainTemplate } from 'src/components/templates/MainTemplate'
import { Main } from 'src/components/index/main/Main'
import HomeAboutSection from 'src/components/organisms/HomeAboutSection/HomeAboutSection'
import HomeSupportSection from 'src/components/organisms/HomeSupportSection/HomeSupportSection'
import ContactForm from 'src/components/organisms/ContactForm/ContactForm'

import SEO from '../components/seo/seo'
import HomeOfferSection from '../components/organisms/HomeOfferSection/HomeOfferSection'

const IndexPage = () => (
  <MainTemplate>
    <SEO description='dsafagaeggr' title='Maszyny przemysłowe i rolnicze | Kuliberda.tech' />
    <Main />
    <HomeAboutSection />
    <HomeOfferSection />
    <HomeSupportSection />
    <ContactForm />
  </MainTemplate>
)

export default IndexPage
