import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled, { css } from 'styled-components'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'
import { Heading } from 'src/components/common/heading/Heading'
import { Paragraph } from 'src/components/common/paragraph/Paragraph'
import { Button } from 'src/components/common/button/Button'
import { device } from 'src/themes/breakpoints'

const OfferWrapper = styled.section`
  position: relative;

  @media ${device.tablet} {
    margin-bottom: -7.5rem;
  }
`

const StyledHeader = styled(Heading)`
  position: relative;
  z-index: 2;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.btnRed};
`

const StyledParagraph = styled(Paragraph)`
  position: relative;
  z-index: 2;
  margin-bottom: 5rem;

  @media ${device.tablet} {
    margin-bottom: 3rem;
  }

  ${({ title }) =>
    title &&
    css`
      margin: 0;
      padding: 1rem;

      @media ${device.tablet} {
        margin: 0;
      }
    `}
`

const StyledWrapper = styled(Wrapper)`
  padding-top: 5rem;
  padding-bottom: 5rem;
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.colors.bgGray};

    @media ${device.tablet} {
      height: 50%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0rem;
  }
`

const StyledTextContainer = styled.div`
  @media ${device.tablet} {
    flex-basis: 45%;
    margin-right: 5%;
  }

  @media ${device.laptop} {
    flex-basis: 35%;
  }
`

const StyledImagesContainer = styled.div`
  @media ${device.tablet} {
    flex-basis: 50%;
    display: flex;
    align-items: center;
  }

  @media ${device.laptop} {
    flex-basis: 60%;
  }
`

const StyledBox = styled.div`
  @media ${device.tablet} {
    flex-basis: 45%;

    :first-of-type {
      margin-right: 10%;
    }
  }
`

const StyledRectangle = styled.div`
  text-align: center;
  border-radius: 2.4rem;
  background: ${({ theme }) => theme.colors.primaryWhite};
  box-shadow: 0 4px 24px ${({ theme }) => theme.colors.boxShadow};
  position: relative;
  z-index: 2;
  max-width: 40rem;
  margin: 0 auto 3rem;

  @media ${device.tablet} {
    max-width: 100%;
    margin: 0 0 4rem auto;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const StyledButton = styled(Button)`
  display: none;

  @media ${device.tablet} {
    background: ${({ theme }) => theme.colors.bgBlue};
    display: block;
    position: relative;
    z-index: 2;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      background: ${({ theme }) => theme.colors.bgBlue};
      display: block;
      position: relative;
      z-index: 2;
      margin: 0 auto;

      @media ${device.mobileXl} {
        position: relative;
      }

      @media ${device.tablet} {
        display: none;
      }
    `}
`

const HomeOfferSection = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulHome {
          offerHeader
          offerParagraph
        }
        allContentfulAsset(filter: { title: { in: ["transport-line", "conveyors", "cnc", "ironwork", "welding"] } }) {
          nodes {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      const { offerHeader, offerParagraph } = data.contentfulHome
      const [ironwork, conveyors, cnc, transportLine, welding] = data.allContentfulAsset.nodes

      return (
        <OfferWrapper>
          <StyledWrapper>
            <StyledTextContainer>
              <StyledHeader as='h2' h2>
                {offerHeader}
              </StyledHeader>
              <StyledParagraph>{offerParagraph}</StyledParagraph>
              <StyledButton to='/oferta'>Wiecej &gt;</StyledButton>
            </StyledTextContainer>
            <StyledImagesContainer>
              <StyledBox>
                <StyledRectangle>
                  <Image fluid={transportLine.fluid} />
                  <StyledParagraph title='true'>Linie transportujące</StyledParagraph>
                </StyledRectangle>
                <StyledRectangle>
                  <Image fluid={conveyors.fluid} />
                  <StyledParagraph title='true'>Przenośnik</StyledParagraph>
                </StyledRectangle>
              </StyledBox>
              <StyledBox>
                <StyledRectangle>
                  <Image fluid={cnc.fluid} />
                  <StyledParagraph title='true'>Usługi CNC</StyledParagraph>
                </StyledRectangle>
                <StyledRectangle>
                  <Image fluid={welding.fluid} />
                  <StyledParagraph title='true'>Spawalnictwo</StyledParagraph>
                </StyledRectangle>
                <StyledRectangle>
                  <Image fluid={ironwork.fluid} />
                  <StyledParagraph title='true'>Ślusarstwo</StyledParagraph>
                </StyledRectangle>
              </StyledBox>
            </StyledImagesContainer>
            <StyledButton to='/oferta' mobile='true'>
              Wiecej &gt;
            </StyledButton>
          </StyledWrapper>
        </OfferWrapper>
      )
    }}
  />
)

export default HomeOfferSection
