import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'
import { Heading } from 'src/components/common/heading/Heading'
import { Button } from 'src/components/common/button/Button'
import { device } from 'src/themes/breakpoints'

import bg from 'src/images/machine.svg'

const StyledWrapper = styled(Wrapper)`
  padding-bottom: 4.5rem;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right;

  @media ${device.laptop} {
    padding-bottom: 9rem;
  }
`

const StyledHeader = styled(Heading)`
  text-align: center;
  margin-bottom: 3rem;
`

const StyledButton = styled(Button)`
  border: none;

  @media ${device.mobileXl} {
    align-self: center;
  }
`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileXl} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const StyledRow = styled.label`
  position: relative;
  width: 100%;

  @media ${device.mobileXl} {
    :nth-of-type(1) {
      margin-right: 10%;
    }

    :nth-of-type(1),
    :nth-of-type(2) {
      flex-basis: 45%;
    }
  }
`

const StyledInput = styled(Field)`
  padding: 0.4rem 0;
  font-family: 'Montserrat', sans-serif;
  font-size: ${({ theme }) => theme.fonts.size.s};
  color: ${({ theme }) => theme.colors.fontGray};
  width: 100%;
  border: none;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.fontGray};
  margin-bottom: 3rem;
  background: transparent;

  /* ::placeholder,
  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    font-size: ${({ theme }) => theme.fonts.size.s};
    color: ${({ theme }) => theme.colors.fontGray};
  } */

  @media ${device.mobileXl} {
    font-size: ${({ theme }) => theme.fonts.size.m};
  }
`

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  font-size: ${({ theme }) => theme.fonts.size.xxs};
  color: ${({ theme }) => theme.colors.btnRed};
  bottom: 1.5rem;
`

const ContactForm = () => (
  <StyledWrapper as='section'>
    <StyledHeader as='h2' h2>
      Wyślij wiadomość
    </StyledHeader>
    <Formik
      initialValues={{ email: '', name: '', message: '' }}
      validate={values => {
        const errors = {}
        if (!values.email) {
          errors.email = 'To pole jest wymagane!'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Nieprawidłowy adres email'
        }

        if (!values.name) errors.name = 'To pole jest wymagane!'

        if (!values.message) errors.message = 'Wiadomość jest za krótka!'

        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          // alert(JSON.stringify(values, null, 2))
          setSubmitting(false)
        }, 400)
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <StyledRow>
            <StyledInput type='email' name='email' placeholder='Email' />
            <StyledErrorMessage name='email' component='div' />
          </StyledRow>
          <StyledRow>
            <StyledInput type='text' name='name' placeholder='Imię' />
            <StyledErrorMessage name='name' component='div' />
          </StyledRow>
          <StyledRow>
            <StyledInput name='message' component='textarea' rows='3' placeholder='Wiadomość' />
            <StyledErrorMessage name='message' component='div' />
          </StyledRow>
          <StyledButton as='button' type='submit' disabled={isSubmitting}>
            Wyślij &gt;
          </StyledButton>
        </StyledForm>
      )}
    </Formik>
  </StyledWrapper>
)

export default ContactForm
