import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Wrapper } from 'src/components/common/wrapper/Wrapper'
import { Heading } from 'src/components/common/heading/Heading'
import { Paragraph } from 'src/components/common/paragraph/Paragraph'
import { device } from 'src/themes/breakpoints'

import bg from 'src/images/web-home-need-help.png'

const SupportWrapper = styled.section`
  padding: 4rem 0;
  margin-bottom: 4.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.bgBlue};
  background: url(${bg}) no-repeat center center / cover;

  @media ${device.mobileXl} {
    padding: 6rem 0;
  }

  @media ${device.tablet} {
    padding: 10rem 0;
  }

  @media ${device.laptop} {
    padding: 16rem 0;
    margin-bottom: 9rem;
  }
`

const StyledHeader = styled(Heading)`
  margin-bottom: 2rem;
  font-size: ${({ theme }) => theme.fonts.size.xl};
  color: ${({ theme }) => theme.colors.bgBlue};

  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fonts.size.xxxxl};
  }
`

const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.semi};
  color: ${({ theme }) => theme.colors.bgBlue};

  @media ${device.laptop} {
    margin: 0 auto;
    max-width: 55rem;
    font-size: ${({ theme }) => theme.fonts.size.xxxl};
  }
`

const HomeSupportSection = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulHome {
          helpHeader
          helpParagraph
        }
      }
    `}
    render={data => {
      const { helpHeader, helpParagraph } = data.contentfulHome
      return (
        <SupportWrapper>
          <Wrapper>
            <StyledHeader as='h2' h2>
              {helpHeader}
            </StyledHeader>
            <StyledParagraph>{helpParagraph}</StyledParagraph>
          </Wrapper>
        </SupportWrapper>
      )
    }}
  />
)

export default HomeSupportSection
